import React, {FC} from 'react'
import {Toolbar} from 'components'
import {PageData} from '_metronic/layout/core'
import EndpointTile from './components/EndpointTile/index'
import {AdvancedRealTimeChart} from 'react-ts-tradingview-widgets'
import Tile from './components/Tile'
import {NodeType} from 'consts'

const ChainOverview: FC = () => {
  return (
    <>
      <PageData pageTitle='Chain Overview'>Chain Overview</PageData>
      <Toolbar />
      <div>
        <div className='row g-12 g-xl-12'>
          <div className='col-sm-12 col-xl-4'>
            <div className='row g-12 g-xl-12'>
              <div className='col-sm-6 col-xl-12 mb-6'>
                <Tile image={''} title='Guild Validations' link={'/validations'} />
              </div>
              <div className='col-sm-6 col-xl-12 mb-6'>
                <Tile image={''} title='Guild Statistics' link={'/stats/guild'} />
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-xl-8 h-auto min-h-350px mb-6'>
            <AdvancedRealTimeChart
              theme='dark'
              symbol={'BITTREX:WAXPUSD'}
              allow_symbol_change={false}
              save_image={false}
              hide_side_toolbar={true}
              interval={'D'}
              autosize
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/seed.svg'}
              title='Seed Endpoints'
              nodeType={NodeType.NODE_SEED}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/seed'}
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/api.svg'}
              title='Api Endpoints'
              nodeType={NodeType.NODE_API}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/api'}
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/wallet.svg'}
              title='Wallet Endpoints'
              nodeType={NodeType.NODE_WALLET}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/wallet'}
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/history.svg'}
              title='History Endpoints'
              nodeType={NodeType.NODE_HISTORY}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/history'}
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/hyperion.svg'}
              title='Hyperion Endpoints'
              nodeType={NodeType.NODE_HYPERION}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/hyperion'}
            />
          </div>
          <div className='col-6 col-xl-4 mb-6'>
            <EndpointTile
              image={'/media/icons/blacklusion-duotone/atomic.svg'}
              title='Atomic Endpoints'
              nodeType={NodeType.NODE_ATOMIC}
              status='down'
              statusValue={40.5}
              statusDesc='more impressions'
              link={'/reports/nodes/atomic'}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ChainOverview
