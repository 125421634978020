import cn from 'classnames'
import {defaultEndpointContent} from 'consts'
import {getFilterParams} from 'helpers/getFilterParams'
import useOnClickOutside from 'hooks/useOnClickOutside'
import React, {FC, useEffect, useRef, useState} from 'react'
import {PageData} from '_metronic/layout/core'
import {useLocation, useParams} from 'react-router-dom'
import {useLazyQuery, useQuery} from '@apollo/client'
import {GET_ENDPOINTS} from 'queries/getEndpoints'
import {GET_GUILDS} from 'queries/getGuilds'
import {NoDataAvailable, SyntaxHighlighter, Toolbar, Map, Button, FilterModal} from 'components'
import {EndpointTable} from './components'
import _ from 'lodash'

const highlightStyles = {
  "hljs-comment": {
    "color": "#565674"
  },
  "code[class*=\"language-\"]": {
    "color": "#fff"
  }
}
const sliderConfig = {step: 1, minValue: 60, maxValue: 1440, unit: 'min'}

const endpointContent: FilterContent[] = [...defaultEndpointContent,
  {
    header: 'Expert Settings',
    label: 'Query Method',
    secondaryLabel: true,
    isSelect: true,
    elements: [
      {
        type: 'select',
        label: 'Atleast one Validation',
        value: 'atleastone',
      },
      {
        type: 'select',
        label: 'All Validations',
        value: 'all',
      },
    ],
    hint: 'If set to "All Validations", all validations within the TimeOffset need to be working; If set to "Atleast one" only a single Validation needs to work in order to score a working status',
    hidden: true
  },
  {
    label: 'Time Offset',
    secondaryLabel: true,
    elements: [
      {
        type: 'secondaryRangeSlider',
        value: 'timeoffset',
        sliderConfig
      }
    ],
    hint: 'The bigger the TimeOffset the more validations will be used to calculate the List. Together with the QueryMethod a bigger TimeOffset will make it more strict (all validations) or loose (atleast one) to score a working status',
    hidden: true
  }
]

const defaultRanges: RangeItem[] = [
  {name: 'timeoffset', values: [60]}
]

const defaultTimeOffsetInMin = 60

const Report: FC = () => {
  const linkProps = useParams<{nodeType: string}>()
  const title = _.startCase(linkProps.nodeType) + ' Endpoints'
  const [showModal, setShowModal] = useState(false)
  const [content, setContent] = useState<FilterContent[]>(endpointContent)
  const [endpoints, setEndpoints] = useState<Endpoint[]>([])
  const [guilds, setGuilds] = useState<Guild[]>([])
  const nodeType = 'NODE_' + linkProps.nodeType.toUpperCase()
  const [endpointUrls, setEndpointUrls] = useState<string[]>([])
  const [markers, setMarkers] = useState<Marker[]>([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [selectedOption, setSelectedOption] = useState<FilterItem | undefined>(undefined)
  const [multiSelectedOptions, setMultiSelectedOptions] = useState<FilterItem[]>([])
  const [currentFilter, setCurrentFilter] = useState<FilterParams>({})
  const [ranges, setRages] = useState(defaultRanges)
  const defaultQueryMethod = nodeType === 'NODE_SEED' ? 'ATLEAST_ONE_SUCCESSFUL_VALIDATION' : 'ALL_SUCCESSFUL_VALIDATION'
  const [queryMethod, setQueryMethod] = useState(defaultQueryMethod)
  const [timeOffsetInMin, setTimeOffsetInMin] = useState(defaultTimeOffsetInMin)
  const mapRef = useRef<HTMLDivElement>(null)
  const endpointRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef(null)
  const {search} = useLocation()

  const [getEndpoints, {data: endpointsData, error: endpointsError, loading: endpointsLoading, refetch, called}] = useLazyQuery<QueryEndpointsRes>(GET_ENDPOINTS)
  const guildResponse = useQuery<QueryGuildsRes>(GET_GUILDS, {
    variables: {startDate: new Date(Date.now() - 60000).toDateString()},
  })

  useOnClickOutside(modalRef, () => {setShowModal(false)})

  const getDefaultSelectedOption = () => {
    return endpointContent[endpointContent.length - 2].elements[nodeType === 'NODE_SEED' ? 0 : 1]
  }

  useEffect(() => {
    const currentContent = linkProps.nodeType === 'seed' ? endpointContent.map(i => ({...i, elements: i.elements.filter(el => el.value !== 'ssl')})) : endpointContent
    setContent(currentContent)
    if (endpointsData?.getEndpoints) {
      handleSort(currentFilter)
      const currentServerVersions: FilterItem[] = []
      endpointsData.getEndpoints.forEach(i => {
        if (i.server_version) {
          const server_version = i.server_version.split(', ')
          server_version.forEach(el => {
            currentServerVersions.push({ type: 'select', label: el, value: el })
          })
        }
      })
      if (currentServerVersions.length) {
        const serverVersion = [...currentContent]
        serverVersion.splice(1, 0, {
          label: 'Server version',
          isMultiSelect: true,
          elements: _.uniqBy(currentServerVersions, 'value')
        })
        setContent(serverVersion)
      }
    }
  }, [endpointsData])


  useEffect(() => {
    if (guildResponse.data?.getGuilds)
      setGuilds(guildResponse.data.getGuilds)
  }, [guildResponse.data])

  useEffect(() => {
    if (search.length && !endpointsLoading) {
      const items: FilterItem[] = []
      const options: FilterItem[] = []
      const multiOptions: FilterItem[] = []
      content.forEach(i => {
        if (i.isMultiSelect) {
          return multiOptions.push(...i.elements)
        }
        if (i.isSelect) {
          return options.push(...i.elements)
        }
        return items.push(...i.elements)
      })
      const itemVerification = !!items.length && new RegExp(items.map(i => i.value.toLowerCase()).join('=t|') + '=t')
      const optionVerification = !!options.length && new RegExp(`querymethod=${options.map(i => `(${i.value.toLowerCase()})`).join('|')}`)
      const multiOptionsVerification = !!multiOptions.length && new RegExp(multiOptions.map(i => i.value.toLowerCase()).join('|'))
      const rangeVerification = new RegExp('timeoffset=\\[\\d{2,4}\\]')
      const currentFilterParams = getFilterParams(search, 'multi', itemVerification, optionVerification, multiOptionsVerification, rangeVerification, [sliderConfig.minValue, sliderConfig.maxValue])
      if (currentFilterParams) {
        setCurrentFilter(currentFilterParams.params)
        let currentQueryMethod
        let timeOffset
        if (currentFilterParams.params.selectedOption) {
          if (currentFilterParams.params.selectedOption.value === 'all') {
            currentQueryMethod = 'ALL_SUCCESSFUL_VALIDATION'
          } else {
            currentQueryMethod = 'ATLEAST_ONE_SUCCESSFUL_VALIDATION'
          }
        } else {
          currentQueryMethod = defaultQueryMethod
        }
        if (currentFilterParams.params.ranges?.length) {
          timeOffset = currentFilterParams.params.ranges[0].values[0]
        } else {
          timeOffset = defaultTimeOffsetInMin
        }
        if (!called) {
          getEndpoints({variables: {nodeType, getOnlyWorking: false, timeOffsetInMin: timeOffset, queryMethod: currentQueryMethod}})
        } else {
          if (currentQueryMethod !== queryMethod || timeOffset !== timeOffsetInMin) {
            refetch({nodeType, getOnlyWorking: false, timeOffsetInMin: timeOffset, queryMethod: currentQueryMethod})
          } else {
            handleSort(currentFilterParams.params)
          }
        }
        setQueryMethod(currentQueryMethod)
        setTimeOffsetInMin(timeOffset)
      }
    } else {
      setCurrentFilter({})
      if (!called) {
        getEndpoints({variables: {nodeType, getOnlyWorking: false, timeOffsetInMin, queryMethod}})
      } else {
        if (!endpointsLoading && (defaultTimeOffsetInMin !== timeOffsetInMin || defaultQueryMethod !== queryMethod)) {
          refetch({nodeType, getOnlyWorking: false, timeOffsetInMin: defaultTimeOffsetInMin, queryMethod: defaultQueryMethod})
          setQueryMethod(defaultQueryMethod)
          setTimeOffsetInMin(defaultTimeOffsetInMin)
        }
      }
    }
  }, [search, content])

  useEffect(() => {
    if (!search.length) {
      getEndpoints({variables: {nodeType, getOnlyWorking: false, timeOffsetInMin: defaultTimeOffsetInMin, queryMethod: defaultQueryMethod}})
    }
  }, [nodeType])

  const handleReset = () => {
    setSelectedItems([])
    setSelectedOption(getDefaultSelectedOption())
    setMultiSelectedOptions([])
    setRages(defaultRanges)
    setCurrentFilter({})
    endpointsData?.getEndpoints && sortEndpoints(endpointsData.getEndpoints)
  }

  const sortEndpoints = (data: Endpoint[]) => {
    setEndpoints(data)
    const currentEndpointUrls: string[] = []
    const currentMarkers: Marker[] = []
    data.forEach((i) => {
      currentEndpointUrls.push(i.endpoint_url)
      if (i.location_longitude && i.location_latitude) {
        currentMarkers.push({
          url: i.endpoint_url,
          isWorking: i.all_checks_ok === 4,
          coordinates: [i.location_longitude, i.location_latitude],
        })
      }
    })
    setEndpointUrls(currentEndpointUrls)
    setMarkers(currentMarkers)
  }

  const handleSort = (params: FilterParams) => {
    if (endpointsData?.getEndpoints) {
      let currentEndpoints = endpointsData.getEndpoints
      if (params.selectedItems?.length) {
        const currentParams = params.selectedItems
        if (currentParams.includes('ssl')) {
          currentEndpoints = currentEndpoints.filter(i => i.is_ssl)
        }
        if (currentParams.includes('working')) {
          currentEndpoints = currentEndpoints.filter(i => i.all_checks_ok === 4)
        }
        setSelectedItems(currentParams)
      } else {
        setSelectedItems([])
      }
      if (params.multiSelectedOptions?.length) {
        const currentParams = params.multiSelectedOptions.map(i => i.value)
        currentEndpoints = currentEndpoints.filter(i => {
          let isMatched = false
          const currentServerVersion = i.server_version?.split(', ')
          currentServerVersion && currentServerVersion.forEach(i => {
            if (currentParams.includes(i)) {
              isMatched = true
            }
          })
          return isMatched
        })
        setMultiSelectedOptions(params.multiSelectedOptions)
      } else {
        setMultiSelectedOptions([])
      }
      if (params.ranges?.length) {
        setRages(params.ranges)
      } else {
        setRages(defaultRanges)
      }
      if (params.selectedOption) {
        setSelectedOption(params.selectedOption)
      } else {
        setSelectedOption(getDefaultSelectedOption())
      }
      sortEndpoints(currentEndpoints)
    }
    if (endpointRef.current){
      const y = endpointRef.current.getBoundingClientRect().height + endpointRef.current.getBoundingClientRect().top
      if (y > window.screen.height / 2) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        return
      }
    }
    if (mapRef.current) {
      const y = mapRef.current.getBoundingClientRect().top
      if (y > 0 && y < window.screen.height / 2) {
        setTimeout(() => {
          if (mapRef.current) {
            const b = mapRef.current.offsetTop
            window.scrollTo({top: b - 135, behavior: 'smooth'})
          }
        })
      }
    }
  }

  return (
    <>
      <PageData link='/overview' pageTitle={title}>{title}</PageData>
      <Toolbar
        rightChildren={
          <>
            <Button
              title='Filter'
              pulse
              icon='/general/gen031.svg'
              loading={endpointsLoading || guildResponse.loading}
              onClick={() => setShowModal(true)}
              disabled={!!endpointsError || !!guildResponse.error}
            />
            <div className={cn('renderModal', {show: showModal})} ref={modalRef}>
              <FilterModal
                currentRanges={ranges}
                defaultRanges={defaultRanges}
                content={content}
                filterType='multi'
                showModal={showModal}
                currentSelectedItems={selectedItems}
                defaultSelectedOption={getDefaultSelectedOption()}
                currentSelectedOption={selectedOption}
                defaultMultiSelectedOptions={multiSelectedOptions}
                onReset={handleReset}
                onSubmit={handleSort}
                hideModal={() => setShowModal(false)}
              />
            </div>
          </>
        }
      />
      <div className='col-xxl-12'>
        {endpointsError || guildResponse.error ? (
          <NoDataAvailable />
        ) : (
          <>
            <div ref={endpointRef}>
              <EndpointTable
                className='card-xxl-stretch mb-5 mb-xxl-8'
                endpoints={endpoints}
                guilds={guilds}
                loading={endpointsLoading || guildResponse.loading}
                error={!!endpointsError || !!guildResponse.error}
                nodeType={linkProps.nodeType}
              />
            </div>
            <div className='mb-5 mb-xxl-8' ref={mapRef}>
              <Map markers={markers} loading={endpointsLoading} />
            </div>
            <div className='mb-5 mb-xxl-8'>
              <SyntaxHighlighter
                header='Text list'
                title='only working endpoints are shown'
                loading={endpointsLoading}
                data={endpointUrls}
                fileName='data.txt'
                config={linkProps.nodeType === 'seed'}
                style={linkProps.nodeType === 'seed' ? highlightStyles : undefined}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Report
