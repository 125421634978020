import cn from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, {FC, useEffect, useRef, useState} from 'react'
import {Range} from 'react-date-range'
import {useMediaQuery as rrUseMediaQuery} from 'react-responsive'
import {useParams} from 'react-router'
import {useQuery} from '@apollo/client'
import {useHistory, useLocation} from 'react-router-dom'
import {getDateValue} from 'helpers/getDateValue'
import useOnClickOutside from 'hooks/useOnClickOutside'
import {PageData} from '_metronic/layout/core'
import {ContentType, defaultDateContent, defaultDateValue, defaultDateRange} from 'consts'
import {
  NoDataAvailable,
  ContentTable,
  Toolbar,
  DateFilter,
  DateRangeCalendar,
  Button,
  FilterModal,
} from 'components'
import Skeleton from 'react-loading-skeleton'
import useMediaQuery from 'hooks/useMediaQuery'
import {VALIDATION_BY_GUILD} from 'queries/getValidationByGuild'
import './style.scss'

const GuildStatistics: FC = () => {
  const props = useParams<{guild: string}>()
  const {search} = useLocation()
  const history = useHistory()

  const [nodes, setNodes] = useState<NodeValidation[]>([])
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [dateValue, setDateValue] = useState(defaultDateValue)
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState<Range>(defaultDateRange)
  const [minDate, setMinDate] = useState<string>()

  const modalRef = useRef(null)
  const calendarRef = useRef(null)
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(modalRef, () => {
    isMobileScreen ? !showCalendar && setShowFilterModal(false) : setShowFilterModal(false)
  })
  useOnClickOutside(calendarRef, () => setShowCalendar(false))

  const {data, loading, error} = useQuery<QueryValidationByGuildRes>(VALIDATION_BY_GUILD, {
    variables: {guild: props.guild},
  })

  const isMobileScreen = rrUseMediaQuery({query: '(max-width: 767px)'})
  const isSmallScreen = rrUseMediaQuery({query: '(max-width: 992px)'})
  const fullItems = useMediaQuery(ref, 936)

  useEffect(() => {
    if (search) {
      const currentDateValue = getDateValue(search)
      if (currentDateValue) {
        if (currentDateValue !== dateValue) {
          setDateValue(currentDateValue)
          setDateRange((current) => {
            return {
              ...current,
              startDate: moment(currentDateValue.parameter[0]).toDate(),
              endDate: moment(currentDateValue.parameter[1]).toDate(),
            }
          })
        }
      } else {
        history.replace({search: ''})
      }
    }
  }, [search])

  useEffect(() => {
    if (data?.getValidationByGuild.nodes) {
      const currentNodes: NodeValidation[] = []
      data.getValidationByGuild.nodes.forEach((i: NodeValidationArray) => {
        i.nodes?.forEach((node) => currentNodes.push(node))
      })
      getMinDate(currentNodes)
      setNodes(currentNodes)
    }
  }, [data])

  const handleDateValueChange = (dateValue: DateItem, dateRange?: Range) => {
    if (dateRange) {
      setDateRange(dateRange)
    } else {
      setDateRange({
        key: 'selection',
        startDate: moment(dateValue.parameter[0]).toDate(),
        endDate: moment(dateValue.parameter[1]).toDate(),
      })
    }
    setDateValue(dateValue)
  }

  const getMinDate = (nodes: NodeValidation[]) => {
    let date = moment().toISOString()
    nodes.forEach((i) => {
      i.statistics?.availability?.forEach((i) => {
        if (moment(date).isAfter(moment(i.date))) {
          date = i.date
        }
      })
    })
    setMinDate(date)
  }

  return (
    <>
      <PageData
        link='/stats/guild'
        loading={loading}
        icon={data && data.getValidationByGuild.guild_logo_256_url}
        pageTitle={_.startCase(props.guild) + ' Statistics'}
      >
        {props.guild}
      </PageData>
      <Toolbar
        rightChildren={
          <>
            {!isMobileScreen && (
              <>
                <DateFilter
                  loading={loading}
                  onChange={handleDateValueChange}
                  content={defaultDateContent}
                  defaultDateValue={dateValue}
                  showCalendar={() => setShowCalendar(true)}
                />
                <Button
                  title='Show Validations'
                  loading={loading}
                  onClick={() => history.push('/validations/' + props.guild)}
                  disabled={loading || !!error}
                />
              </>
            )}
            {isMobileScreen && (
              <>
                <Button
                  title='filter'
                  icon='/general/gen031.svg'
                  loading={loading}
                  onClick={() => setShowFilterModal(true)}
                  disabled={loading || !!error}
                />
                <div className={cn('renderModal', {show: showFilterModal})} ref={modalRef}>
                  <FilterModal
                    showModal={showFilterModal}
                    dateFilter={
                      <DateFilter
                        onChange={handleDateValueChange}
                        content={defaultDateContent}
                        defaultDateValue={dateValue}
                        isMobile
                        showCalendar={() => setShowCalendar(true)}
                      />
                    }
                    hideModal={() => setShowFilterModal(false)}
                  />
                </div>
              </>
            )}
            {showCalendar && (
              <div className='calendarWrapper' ref={calendarRef}>
                <DateRangeCalendar onChange={handleDateValueChange} defaultDateRange={dateRange} />
              </div>
            )}
          </>
        }
      />
      <div className='guildValidation' ref={ref}>
        {!loading && error ? (
          <NoDataAvailable />
        ) : (
          <div>
            {loading ? (
              <Skeleton height={530} count={10} className={cn('loading', {'w-100 mr-0': !fullItems || isSmallScreen}) } />
            ) : (
              <div className={cn('items', {fullItems: !fullItems || isSmallScreen})}>
                {nodes.map((i) => (
                  <div className='item mb-6' key={i.node_type + i.endpoint_url}>
                    <ContentTable
                      dateRange={
                        minDate && !dateValue.parameter.length
                          ? [moment().format('YYYY-MM-DD'), moment(minDate).format('YYYY-MM-DD')]
                          : dateValue.parameter
                      }
                      validationObject={i}
                      contentType={ContentType.Statistics}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default GuildStatistics
